remove_error_callback = (element) ->
  form_group = element.closest('.form-group')
  form_group.removeClass('field_with_errors')
  form_group.find('.custom-error').remove()
  form_group.find('.error').remove()

custom_fail_callback = (element, message) ->
  form_group = element.closest('.form-group')
  help_block = form_group.find('.error')

  form_group.addClass('field_with_errors')

  if help_block.length > 0
    help_block.html(message)
  else
    form_group.append("<span class='error custom-error'>#{message}</span>")

validate_field_form = (input) ->
  input      = $(input)
  form       = input.closest('form')
  validators = form[0].ClientSideValidations.settings.validators

  form.isValid(validators)
  remove_error_callback(input)

`export {remove_error_callback, custom_fail_callback, validate_field_form}`
