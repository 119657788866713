import { init_new_time_entry_modal_form } from 'javascripts/components/time_entries/shared/time_entry_functions';
import RequestService from 'javascripts/react/services/request_service';
import i18n from 'javascripts/i18n';
import { remove_error_callback, custom_fail_callback } from 'javascripts/components/shared/rails_validation_helpers';
import { time_entry_is_int } from 'javascripts/components/time_entries/time_validation';
import validate_custom_parameters from 'javascripts/rails.validations.customValidators.js.coffee';
import Utils from 'javascripts/main/utils';

function validateTimeEntry(form) {
  const timeEntryRequired = form.data('time-entry-required');

  const timeEntryExists = form.serializeArray().some(param => param.name.match(/issue\[time_entry\]/));
  const timeEntryValid = !timeEntryRequired || timeEntryExists;
  const timeEntryForm = $('@issue-status-time-entry-form');

  if (timeEntryValid) {
    remove_error_callback(timeEntryForm);
  } else {
    custom_fail_callback(timeEntryForm, i18n.t('issues.status_form.empty_time_entry_error'));
  }

  return timeEntryValid;
}

class StatusFormTimeEntries {
  constructor(statusForm) {
    this.statusForm = statusForm;
    this.timeEntryHiddenFiledsBlockRole = 'status-form-time-entry-input-holder';
    this.request = new RequestService();
    this.initCallbacks();
  }

  initCallbacks() {
    init_new_time_entry_modal_form();

    $(this.statusForm).off('ajax:before', '@time-entry-form-modal-dialog form')
      .on('click', '@remove-time-entry-button', () => {
        this.toggleTimeEntryButton();
        this.removeTimeEntryInfo();
      });

    $(document).off('ajax:before', '@time-entry-form-modal-dialog form')
      .on('ajax:before', '@time-entry-form-modal-dialog form', async (event) => {
        const containers = $(event.target).find('.form-modal-custom-parameters-item.ftdatetime');
        containers.each((_index, container) => {
          Utils.construct_hidden_datetime_param($(container), 'time_entry');
        });
        const customParametersValid = validate_custom_parameters($('@time-entry-parameters-list-container'));

        if (time_entry_is_int($(event.target).find('#time_entry_formatted_spent_time')) || !customParametersValid) {
          event.preventDefault();
          false;
        } else {
          const timeEntryFormData = $(event.target).serializeArray().filter((e) => e.name.match(/time_entry/));
          this.toggleTimeEntryButton();
          await this.addTimeEntryInfo(timeEntryFormData);
          validateTimeEntry(this.statusForm);
        }
      });
  }

  toggleTimeEntryButton() {
    this.statusForm.find('.status-form-time-entry-widget').toggle();
  }

  async addTimeEntryInfo(data) {
    const unpreparedTime = data && data.find(e => e.name === 'time_entry[formatted_spent_time]').value;
    const timeEntryStatusFormParams = data.map(e => ({ ...e, name: e.name.replace('time_entry', 'issue[time_entry]') }));
    const response = await this.request.get('/issues/status_forms/time_entries', { time: unpreparedTime });
    const spentTime = response ? response.time : '';

    this.statusForm.find('.time-entry-widget-block').append(this.constructor.timeEntryInfoBlock(spentTime));
    this.addTimeEntryHiddenFields(timeEntryStatusFormParams);
  }

  addTimeEntryHiddenFields(preparedParams) {
    this.statusForm.append(`<div data-role="${this.timeEntryHiddenFiledsBlockRole}"></div>`);
    preparedParams.forEach((e) => {
      $(`@${this.timeEntryHiddenFiledsBlockRole}`).append(`<textarea class="hidden" name="${e.name}">${e.value}</textarea>`);
    });
  }

  removeTimeEntryInfo() {
    this.statusForm.find('@time-entry-block').remove();
    $(`@${this.timeEntryHiddenFiledsBlockRole}`).remove();
  }

  static timeEntryInfoBlock(spentTime) {
    return `<div class='status-form-time-entry-block' data-role="time-entry-block">
              <div class='content'>
                <div class='spent-time-info clearfix'>
                  <div class='title'>
                    ${i18n.t('status_form.spent_time', { spentTime })}
                  </div>
                </div>
              </div>
              <a class='btn-small-reset' href='#' data-role='remove-time-entry-button'></a>
            </div>`;
  }
}

export { StatusFormTimeEntries, validateTimeEntry };
