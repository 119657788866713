`import { normalizedDateForSave } from 'javascripts/react/services/date_time_formatter_service'`

Utils = do ->
  construct_hidden_datetime_param: (container, object) ->
    date = container.find("input[name='#{object}[datetime_params][date]']").val()
    hour = container.find("input[name='#{object}[datetime_params][hour]']").val() || '00'
    minute = container.find("input[name='#{object}[datetime_params][minute]']").val() || '00'
    hidden_input = container.find("@#{object}-datetime-hidden-parameter")
    if date
      normalizedDate = normalizedDateForSave(date)
      hidden_input.val("#{normalizedDate} #{hour}:#{minute}")
    else
      hidden_input.val('')

  random_string: (length) ->
    chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    result = ''
    for i in [length..0]
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    result

`export default Utils`
