`import { validateAttachments } from 'javascripts/components/issues/status_form/attachments'`
`import { remove_error_callback, custom_fail_callback }
  from 'javascripts/components/shared/rails_validation_helpers'`
`import CustomParametersValidator from 'javascripts/components/custom_parameters/validator'`
`import { validateTimeEntry } from 'javascripts/components/issues/status_form/time_entries'`
`import { normalizedDateForSave } from 'javascripts/react/services/date_time_formatter_service'`
`import { validateCheckList, showTabValidationPopup }
  from 'javascripts/components/issues/status_form/required_check_list'`
`import i18n from 'javascripts/i18n'`

validateCustomParameters = (form) ->
  custom_param_block = form
    .find('@update-issue-status-custom-parameters-list-container')
  return true unless custom_param_block && custom_param_block.length > 0

  validator = new CustomParametersValidator(custom_param_block)
  return validator.validate()

validateComment = (form) ->
  comment_input = form.find('.status-form-comment-textarea')
  return true unless comment_input && comment_input.length > 0

  comment_required = comment_input.attr('data-required') == 'true'
  comment_input_id = comment_input.attr('id')
  comment_present = CKEDITOR.instances[comment_input_id].getData().trim() != ''
  comment_valid = !comment_required || comment_present

  if comment_valid
    remove_error_callback(comment_input)
    return true
  else
    custom_fail_callback(comment_input, i18n.t('errors.blank'))
    return false

validateAssignee = (form) ->
  widgetContainer = form.find('.form-group-status-assignee[data-required]')
  assigneeElement = widgetContainer.find('input#issue_assignee_id')
  groupElement = widgetContainer.find('input#issue_group_id')
  return true unless assigneeElement && assigneeElement.length > 0

  if assigneeElement.val() != '' || groupElement.val() != ''
    remove_error_callback(assigneeElement)
    return true
  else
    custom_fail_callback(assigneeElement, i18n.t('issues.status_form.empty_assignee_error'))
    return false

validateDelayedTo = (form) ->
  element = form.find('@planned-solution-time')
  return true unless element && element.length > 0

  presented = delayed_to_present(element)
  valid_date = delayed_to_valid_time(element)
  delayed_to_valid = presented && valid_date

  if delayed_to_valid
    remove_error_callback(element)
  else if !presented
    custom_fail_callback(element, i18n.t('issues.status_form.empty_delayed_to'))
  else if !valid_date
    custom_fail_callback(element, i18n.t('issues.status_form.wrong_delayed_to'))

  return delayed_to_valid

delayed_to_present = (element) ->
  return element.find('input@status-date-picker').val() != ''

delayed_to_valid_time = (element) ->
  date_input = element.find('input@status-date-picker')
  date_value = normalizedDateForSave(date_input.val())
  timezone_offset = date_input.attr('data-timezone-offset')
  time_inputs = element.find('input@time-picker')
  time_value = time_inputs[0].value + ':' + time_inputs[1].value

  selected_date = new Date("#{date_value} #{time_value} #{timezone_offset}")
  current_date = new Date()

  return current_date < selected_date

validateStatusForm = (form) ->
  commentValid = validateComment(form)
  attachmentsValid = validateAttachments(form)
  delayedToValid = validateDelayedTo(form)
  customParametersValid = validateCustomParameters(form)
  timeEntryValid = validateTimeEntry(form)
  checkListValid = validateCheckList(form)
  assigneeValid = validateAssignee(form)

  mainAttributesValid = commentValid && attachmentsValid && delayedToValid &&
    customParametersValid && timeEntryValid && assigneeValid

  formValid = mainAttributesValid && checkListValid

  showTabValidationPopup(form[0].closest('.modal-dialog'), mainAttributesValid, checkListValid) unless formValid

  return formValid

`export default validateStatusForm`
