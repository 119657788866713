`import { remove_error_callback, custom_fail_callback } from 'javascripts/components/shared/rails_validation_helpers'`
`import i18n from '../../i18n'`

$(document).on 'blur', '#time_entry_formatted_spent_time', (e) ->
  element = $(@)
  if time_entry_is_int(element)
    custom_fail_callback(element, i18n.t('time_series.unspecified_format'))
    $('@time-entry-submit').prop('disabled', true)
  else
    $('@time-entry-submit').prop('disabled', false)

    if $(element.closest('.form-group').find('.error')).text() ==
         i18n.t('time_series.unspecified_format')

      remove_error_callback(element)


$(document).on 'keyup', '#time_entry_formatted_spent_time', (e) ->
  element = $(@)
  if time_entry_is_int(element)
    custom_fail_callback(element, i18n.t('time_series.unspecified_format'))
    $('@time-entry-submit').prop('disabled', true)
  else if e.keyCode != 13
    $('@time-entry-submit').prop('disabled', false)

    if $(element.closest('.form-group').find('.error')).text() ==
         i18n.t('time_series.unspecified_format')

      remove_error_callback(element)


time_entry_is_int = (element) ->
  int_pattern = /^[1-9]+[0-9]*$/g
  element.val().trim().match(int_pattern)

`export {time_entry_is_int}`
