import { remove_error_callback, custom_fail_callback } from 'javascripts/components/shared/rails_validation_helpers';
import i18n from 'javascripts/i18n';
import FormAttachments from 'javascripts/components/shared/form_attachments';

function validateAttachments(form) {
  const attachmentsWindgetButton = form.find('@status-form-file-upload-btn');
  const attachmentsWindgetExists = attachmentsWindgetButton && attachmentsWindgetButton.length > 0;
  if (!attachmentsWindgetExists) { return true; }

  const attachmentsRequired = attachmentsWindgetButton.attr('data-attachments-required') === 'true';
  const attachmentsEmpty = $('@status-form-attachments-list').children().length === 0;
  const attachmentsValid = !attachmentsRequired || !attachmentsEmpty;

  if (attachmentsValid) {
    remove_error_callback($('@status-form-file-upload-btn'));
  } else {
    custom_fail_callback($('@status-form-file-upload-btn'),
      i18n.t('issues.status_form.empty_attachments_error'));
  }

  return attachmentsValid;
}

class StatusFormAttachments extends FormAttachments {
  constructor(modalDialogHolderRole, form) {
    super(modalDialogHolderRole);

    $(this.formSubmitAttachmentButtonSelector).click(() => {
      validateAttachments(form);
    });
  }

  fileRealInputsTemplate() {
    return `<div data-role='status-form-file-input-holder-${this.index}'>
              <input data-role='tackable-file-field' multiple='multiple' type='file'
                     style='display: none;'
                     name='issue[attachments][${this.index}][attachment][]' id='status_form_file_input-${this.index}'
                     data-track-input='status-form-visible-file-name'>

              <input type="hidden" name='issue[attachments][${this.index}][description]'
                     data-role='status-form-attachment-real-description'>

              <input type="hidden" name='issue[attachments][${this.index}][is_public]'
                     data-role='status-form-attachment-real-is-public'>
            </div>`;
  }

  fileInfoTemplate(name, description, isPublic) {
    return `<div class='forms-file-item'>
              <a class='btn-small-reset' href='#' data-index='${this.index}'
                 data-role='status-form-remove-attachment'></a>
              <div class='content'>
                <div class='file-info clearfix'>
                  <div class='title' title='${name}'>
                    ${name}
                  </div>
                  <div class='private-marker ${(isPublic === 'true') ? 'hidden' : ''}'>
                    <div class='fa fa-lock'></div>
                  </div>
                </div>
                <div class='description'>${description}</div>
              </div>
            </div>`;
  }

  initSelectors() {
    this.formAttachmentInputHolderSelector = '@status-form-attachment-input-holders';
    this.formRealAttachmentFieldsSelector = '@status-form-real-attachment-fields';
    this.formAttachmentsListSelector = '@status-form-attachments-list';
    this.formFileInputLabelSelector = '@status-form-file-input-label';
    this.formFileInputLabelForAttribute = 'status_form_file_input';
    this.formSubmitAttachmentButtonSelector = '@status-form-submit-attachment-btn';
    this.formFileUploadModalDialogSelector = '@status-form-file-upload-modal-dialog';
    this.formAttachmentFakeDescriptionSelector = '@status-form-attachment-fake-description';
    this.formAttachmentFakeIsPublicSelector = '@status-form-attachment-fake-is-public';
    this.formAttachmentRealDescriptionSelector = '@status-form-attachment-real-description';
    this.formAttachmentRealIsPublicSelector = '@status-form-attachment-real-is-public';
  }
}

$(document).on('click', '@status-form-remove-attachment', (e) => {
  e.preventDefault();

  const btn = $(e.target);
  const filesHolder = btn.closest('@status-form-attachments-list');
  const attachmentsHolder = $('@status-form-attachment-input-holders');

  btn.closest('.forms-file-item').remove();
  attachmentsHolder.find(`@status-form-file-input-holder-${btn.attr('data-index')}`).remove();

  if (filesHolder.find('.forms-file-item').length === 0) {
    filesHolder.addClass('hidden');
  }
});

export { StatusFormAttachments, validateAttachments };
