export function validateDatetimeFields(container) {
  if (container.length === 0) {
    return false;
  }

  const dateInput = container.find('input@date-picker');
  const hourInput = container.find("input@time-picker[data-type='hour']");
  const minuteInput = container.find("input@time-picker[data-type='minute']");
  const dateInputVal = dateInput.val();
  const hourInputVal = hourInput.val();
  const minuteInputVal = minuteInput.val();
  if ((dateInput && dateInputVal.length === 0)
      || (hourInput && hourInputVal.length === 0)
      || (minuteInput && minuteInputVal.length === 0)) {
    return false;
  }
  return true;
}

export function validateDatetimeForm(form) {
  const formRequired = form.find('@custom-parameters-datetime-inputs-wrapper').data('required');
  if (!formRequired) { return true; }
  const dateValid = validateDatetimeFields(form);
  if (dateValid) { return true; }
  const formGroup = form.find('input@date-picker').closest('.form-group');
  formGroup.find('.custom-datetime-form-error').html(form.data('errorMessage'));
  formGroup.addClass('field_with_errors');
  return false;
}
