import { remove_error_callback, custom_fail_callback } from 'javascripts/components/shared/rails_validation_helpers';
import ValidationService from '../../react/services/validation_service';
import i18n from '../../i18n';

const validateFields = (password, confirmation, currentPassword, userType) => {
  const serviceParams = { passwordRequirements: password.data('password-requirements') };
  const validationService = new ValidationService(serviceParams);
  const checks = [
    [`${userType}_password`, [password.val(), confirmation.val()], ['password']],
    [`${userType}_password_confirmation`, [password.val(), confirmation.val()], ['confirmation']],
  ];
  if (currentPassword.length > 0) {
    checks.push([`${userType}_current_password`, currentPassword.val(), ['presence']]);
  }
  validationService.validate(...checks);

  return validationService.validationErrors();
};

const showFieldError = (field, errors) => {
  remove_error_callback(field);

  const field_id = field.attr('id');
  const fieldError = errors[field_id];
  if (fieldError) {
    custom_fail_callback(field, fieldError);
  }

  const confirmationFieldID = `${field_id}_confirmation`;
  const confirmationField = $(`#${confirmationFieldID}`);

  if (confirmationField.length > 0) {
    if (!errors[confirmationFieldID]) {
      remove_error_callback(confirmationField);
    }

    if (errors[confirmationFieldID] === i18n.t('errors.unconfirmed')) {
      custom_fail_callback(confirmationField, fieldError);
    }
  }

  if (field_id.includes('_confirmation')) {
    const mainFieldId = field_id.replace('_confirmation', '');
    const mainField = $(`#${mainFieldId}`);

    if (errors[mainFieldId]) {
      custom_fail_callback(mainField, errors[mainFieldId]);
    } else {
      remove_error_callback(mainField);
    }
  }
};

const showFieldsError = (fields, errors) => {
  fields.forEach((field) => {
    if (field.length === 0) return;

    showFieldError(field, errors);
  });
};

$(document).on('keyup', '@user-password-field', (e) => {
  const form = $('@user-form-with-pass');
  const userType = form.attr('data-user-type');
  const password = form.find(`#${userType}_password`);
  const confirmation = form.find(`#${userType}_password_confirmation`);
  const currentPassword = form.find(`#${userType}_current_password`);
  const validationErrors = validateFields(password, confirmation, currentPassword, userType);
  const field = $(e.target);

  showFieldError(field, validationErrors);
});

$(document).on('blur', '@user-password-field', (e) => {
  const form = $('@user-form-with-pass');
  const userType = form.attr('data-user-type');
  const password = form.find(`#${userType}_password`);
  const confirmation = form.find(`#${userType}_password_confirmation`);
  const currentPassword = form.find(`#${userType}_current_password`);
  const validationErrors = validateFields(password, confirmation, currentPassword, userType);
  const field = $(e.target);

  showFieldError(field, validationErrors);
});

$(document).on('ajax:before', '@user-form-with-pass', () => {
  const form = $('@user-form-with-pass');
  const userType = form.attr('data-user-type');
  const password = form.find(`#${userType}_password`);
  const confirmation = form.find(`#${userType}_password_confirmation`);
  const currentPassword = form.find(`#${userType}_current_password`);
  const validationErrors = validateFields(password, confirmation, currentPassword, userType);

  showFieldsError([password, confirmation, currentPassword], validationErrors);
  return Object.keys(validationErrors).length === 0;
});

export { showFieldsError, validateFields };
