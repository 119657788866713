import { remove_error_callback, custom_fail_callback }
  from 'javascripts/components/shared/rails_validation_helpers';

export function removeErrorCallback(field) {
  const jField = $(field);
  remove_error_callback(jField);
}

export function validateReactFields() {
  const reactFields = $('[data-validate-on-client-side="true"]');

  if (reactFields.length == 0) { return true; }

  const invalidFields = [];

  reactFields.each((_index, field) => {
    const jField = $(field);
    if (jField.val() == '') {
      invalidFields.push(field);
      custom_fail_callback(jField, jField.attr('data-validate-message'));
    } else {
      removeErrorCallback(field);
    }
  })

  return invalidFields.length == 0;
}
