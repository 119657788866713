import { custom_fail_callback } from 'javascripts/components/shared/rails_validation_helpers';
import { validateDatetimeFields } from './datetime_validators';

class CustomParametersValidator {
  constructor(container) {
    this.container = container;
  }

  validate() {
    if (this.container.length === 0) {
      return false;
    }

    const formGroups = this.container.find('.form-group[data-required="true"]');
    let result = true;

    formGroups.each((index, value) => {
      const element = $(value);
      const field = $(element).data('field');
      let input = null;
      let inputVal = null;

      switch (field) {
        case 'ftstring':
        case 'ftdate':
        case 'ftcheckbox':
          input = element.find('input');
          inputVal = input.val();
          break;
        case 'ftdatetime':
          input = element.find('input@date-picker');
          break;
        case 'ftmultiselect':
          input = element.find('select');
          inputVal = input.find('option:selected');
          break;
        case 'ftselect':
          input = element.find('select');
          inputVal = input.find('option:selected').val();
          break;
        case 'fttext':
          input = element.find('@ckeditor-custom-parameter-field');
          inputVal = CKEDITOR.instances[input.attr('id')].getData().trim();
          break;
        case 'ftsignature':
          input = element.find('@signature-input');
          inputVal = input.val();
        default:
          break;
      }

      if (!this.constructor.inputValid(field, element, input, inputVal)) {
        custom_fail_callback(input, this.container.data('error-message'));
        input.data('valid', false);
        if (result) {
          result = false;
        }
      }

      return true;
    });
    return result;
  }

  static inputValid(field, element, input, inputVal) {
    if (field === 'ftdatetime') { return validateDatetimeFields(element); }
    return !input || inputVal.length !== 0;
  }
}

export default CustomParametersValidator;
