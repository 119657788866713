/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import ModalDialog from 'javascripts/vendors/modal_dialog';

class FormAttachments {
  initVariables(modalDialogHolderRole) {
    this.formInputsHolder = $(this.formAttachmentInputHolderSelector);
    this.newAttachmentModalDialog = $(modalDialogHolderRole);
    this.index = parseInt(this.formInputsHolder.attr('data-index'), 10);
    this.realAttachmentInputs = this.newAttachmentModalDialog
      .find(this.formRealAttachmentFieldsSelector);
    this.attachmentsList = $(this.formAttachmentsListSelector);
  }

  constructor(modalDialogHolderRole) {
    this.initSelectors();
    this.initVariables(modalDialogHolderRole);

    // Append real attachment inputs to holder
    this.realAttachmentInputs.html(this.fileRealInputsTemplate());
    this.newAttachmentModalDialog.find(this.formFileInputLabelSelector).attr('for', `${this.formFileInputLabelForAttribute}-${this.index}`);
    this.formInputsHolder.attr('data-index', this.index + 1);

    this.bindSubmitHandler();
  }

  bindSubmitHandler() {
    $(this.formSubmitAttachmentButtonSelector).click((e) => {
      e.preventDefault();

      const errorsHolder = $(e.target).closest('.file-upload-modal').find('.attachment-error-message');
      if (errorsHolder.html().length > 0) { return; }

      const attachedFiles = this.realAttachmentInputs.find('@tackable-file-field')[0].files;
      if (attachedFiles.length === 0) {
        errorsHolder.html(`<p>${this.realAttachmentInputs.attr('data-empty-error-message')}</p>`);
        return;
      }

      this.fillRealAttachmentInputs();

      this.attachmentsList.append(this.fileInfo());
      this.attachmentsList.removeClass('hidden');

      this.formInputsHolder.append(this.realAttachmentInputs.children());

      ModalDialog.list[this.formFileUploadModalDialogSelector].hide();
    });
  }

  fillRealAttachmentInputs() {
    const fakeDescriptionFieldValue = this.newAttachmentModalDialog
      .find(this.formAttachmentFakeDescriptionSelector).val();
    const fakeIsPublicField = this.newAttachmentModalDialog
      .find(this.formAttachmentFakeIsPublicSelector);

    let fakeIsPublicFieldValue;
    if (fakeIsPublicField.attr('type') === 'checkbox') {
      fakeIsPublicFieldValue = fakeIsPublicField.is(':checked');
    } else {
      fakeIsPublicFieldValue = fakeIsPublicField.val();
    }

    this.realAttachmentInputs.find(this.formAttachmentRealDescriptionSelector)
      .val(fakeDescriptionFieldValue);
    this.realAttachmentInputs.find(this.formAttachmentRealIsPublicSelector)
      .val(fakeIsPublicFieldValue);
  }

  fileInfo() {
    const description = this.realAttachmentInputs.find(this.formAttachmentRealDescriptionSelector).attr('value');
    const isPublic = this.realAttachmentInputs.find(this.formAttachmentRealIsPublicSelector).attr('value');
    const fileNames = [];

    const filesFromInput = this.realAttachmentInputs.find('@tackable-file-field')[0].files;
    Array.prototype.forEach.call(filesFromInput, file => (fileNames.push(file.name)));
    const name = fileNames.join(', ');

    return this.fileInfoTemplate(name, description, isPublic);
  }

  // overridable methods

  fileRealInputsTemplate() { this; }

  fileInfoTemplate(name, description, isPublic) { this; }

  initSelectors() {
    this.formAttachmentInputHolderSelector;
    this.formRealAttachmentFieldsSelector;
    this.formAttachmentsListSelector;
    this.formFileInputLabelSelector;
    this.formFileInputLabelForAttribute;
    this.formSubmitAttachmentButtonSelector;
    this.formFileUploadModalDialogSelector;
    this.formAttachmentFakeDescriptionSelector;
    this.formAttachmentFakeIsPublicSelector;
    this.formAttachmentRealDescriptionSelector;
    this.formAttachmentRealIsPublicSelector;
  }
}

export default FormAttachments;
