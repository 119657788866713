const FORM_VALIDATION_EVENT_NAME = 'validateStatusForm';
const STATUS_FORM_SELECTOR = '[data-role="set-closed-issue-status-form"], [data-role="change-issue-status"]';

function setPointParametersToStatusForm(pointId, checked) {
  const hiddenField = document.querySelector(`#item-${pointId}-checked`);

  if (hiddenField) { hiddenField.value = checked; }
}

function actualizePointsParametersOnStatusForm(pointsIds) {
  const form = document.querySelector(STATUS_FORM_SELECTOR);

  form.querySelectorAll('[data-role="item-checked-field"]').forEach((hiddenField) => {
    hiddenField.remove();
  });

  pointsIds.forEach((id) => {
    const newElement = document.createElement('input');
    newElement.setAttribute('name', 'checked');
    newElement.setAttribute('type', 'hidden');
    newElement.setAttribute('id', `item-${id}-checked`);
    newElement.setAttribute('data-role', 'item-checked-field');
    newElement.setAttribute('value', false);

    form.appendChild(newElement);
  });
}

export {
  FORM_VALIDATION_EVENT_NAME,
  STATUS_FORM_SELECTOR,
  setPointParametersToStatusForm,
  actualizePointsParametersOnStatusForm,
};
