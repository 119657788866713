`import { validateDatetimeFields } from 'javascripts/components/custom_parameters/datetime_validators'`
`import { normalizedDateForInput, normalizeDateTimeForInput } from 'javascripts/react/services/date_time_formatter_service'`

$(document).on 'change', '@date-picker, @filter-data-picker, @status-date-picker, @issue-datetime-picker', ->
  value = $(@).val()
  $(@).val(normalizedDateForInput(value)) if value

$(document).on 'change', '@datetime-picker, .custom-params-main-datetime-field', ->
  value = $(@).val()
  if value
    { date, hour, minute } = normalizeDateTimeForInput(value)
    normalizedValue = "#{date} #{hour}:#{minute}"
    $(@).val(normalizedValue)

$(document).on 'change', '@filter-data-picker', ->
  if $(@).val().length > 0
    $(@).closest('form@column-filter-form, form@phone-call-column-filter-form, form@equipment-column-filter-form, [data-form="filter_form"]').submit()

$(document).on 'change', '@custom-parameters-datetime-inputs-wrapper input', ->
  datetimeWrapper = $(@).closest('@custom-parameters-datetime-inputs-wrapper')
  if validateDatetimeFields(datetimeWrapper)
    datetimeWrapper.find('input@date-picker').data('valid', true)
    formGroup = datetimeWrapper.find('input@date-picker').closest('.form-group')
    formGroup.find('.custom-datetime-form-error').html('')
    formGroup.find('.error').html('')
    formGroup.removeClass('field_with_errors')
  return false

findClosestFieldSet = (currentField) ->
  findFunction = (selector) -> currentField.closest(selector).length > 0
  foundSelector = ['.fields-set', '.form-group'].find(findFunction)
  return currentField.closest(foundSelector)

setCursorToNextField = (currentField, nextFieldSelector, prevFieldSelector) ->
  fieldsSet = findClosestFieldSet(currentField)

  if fieldsSet.length > 0
    if currentField.val().length > 1 && nextFieldSelector
      $(fieldsSet[0]).find(nextFieldSelector).select()

    if currentField.val().length == 0 && prevFieldSelector
      $(fieldsSet[0]).find(prevFieldSelector).select()
      currentField.trigger('change')

# move focus from date to hours field when date field changed
$(document).on 'input', '@issue-datetime-picker, @date-picker, @status-date-picker', (e) ->
  value = event.target.value.replace(/[./-]/g, '');
  if value.length >= 8
    setCursorToNextField($(@), '@time-picker[data-type="hour"]')
    $(@).datetimepicker('hide')

# move focus from date to hours field when date field changed
$(document).on 'change', '@issue-datetime-picker, @date-picker, @status-date-picker', (e) ->
  setCursorToNextField($(@), '@time-picker[data-type="hour"]')

# move focus from hour to minutes field when hour field changed
$(document).on 'input', '@time-picker[data-type="hour"]', (e) ->
  setCursorToNextField($(@), '@time-picker[data-type="minute"]', '@issue-datetime-picker, @date-picker, @status-date-picker')

# move focus from minute to hour field
$(document).on 'input', '@time-picker[data-type="minute"]', (e) ->
  setCursorToNextField($(@), null, '@time-picker[data-type="hour"]')
