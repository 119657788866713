setTableScroll = (table_name) ->
  for scrollable in $("@#{table_name}-scrollable-table")
    scrollable = $(scrollable)
    if scrollable.hasClass('mCustomScrollbar')
      scrollable.mCustomScrollbar('update')
    else
      scrollable.mCustomScrollbar({
        axis: 'x',
        scrollInertia: 0,
        theme: 'minimal-dark',
        advanced:{ autoExpandHorizontalScroll: true, updateOnImageLoad: false },
        mouseWheel: false,
        setLeft: $("@#{table_name}-list-block").data('x-scroll')
      })
    # use .column-cell class to disable dragscroll on interactive elements like links
    scrollable.find('.mCustomScrollBox').dragScroll({
      scrollableArea: '.mCSB_container',
      mCustomScrollbarSelector: "@#{table_name}-scrollable-table",
      scrollContainerSelector: scrollable.data('container-selector') || 'body'
      scrollY: false
    })

`export default setTableScroll`
