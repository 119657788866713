import { FORM_VALIDATION_EVENT_NAME, STATUS_FORM_SELECTOR } from
  'javascripts/react/services/issues/status_form/check_list_events_service';

const ERROR_POPUP_SELECTOR = '[data-role="required-error-popup"]';
const TAB_SELECTOR = '[data-role="form-tab"]';
const TABS_TOGGLER_SELECTOR = '[data-role="form-tabs-toggler"]';

function validateCheckList() {
  const form = document.querySelector(STATUS_FORM_SELECTOR);

  if (!form) { return true; }

  if (form.querySelector('[data-role="read-check-list-forbidden"]')) { return false; }

  // eslint-disable-next-line no-undef
  form.dispatchEvent(new Event(FORM_VALIDATION_EVENT_NAME));

  const itemsValues = [...form.querySelectorAll('[data-role="item-checked-field"]')];

  return itemsValues.length === 0 || !itemsValues.some((item) => item.value.toString() !== 'true');
}

function toggleAttributesTab(modal, tabName) {
  const mainToggler = modal.querySelector(`${TABS_TOGGLER_SELECTOR}[data-tab="main-attributes"]`);
  const mainTab = modal.querySelector(`${TAB_SELECTOR}.main-attributes`);
  const checkListToggler = modal.querySelector(`${TABS_TOGGLER_SELECTOR}[data-tab="required-check-list"]`);
  const checkListTab = modal.querySelector(`${TAB_SELECTOR}.required-check-list`);

  modal.querySelector(`${TABS_TOGGLER_SELECTOR}:not(.active) ${ERROR_POPUP_SELECTOR}`)
    .classList.remove('visible');

  if (tabName === 'main-attributes') {
    mainToggler.classList.add('active');
    mainTab.classList.add('active');
    checkListToggler.classList.remove('active');
    checkListTab.classList.remove('active');
  } else {
    mainToggler.classList.remove('active');
    mainTab.classList.remove('active');
    checkListToggler.classList.add('active');
    checkListTab.classList.add('active');
  }
}

function showTabValidationPopup(modal, mainAttributesValid, checkListValid) {
  const form = modal.querySelector(STATUS_FORM_SELECTOR);

  if (!form) { return; }

  const checkListInvalidPopup = form
    .querySelector(`[data-tab="required-check-list"] > ${ERROR_POPUP_SELECTOR}`);
  const mainAttributesInvalidPopup = form
    .querySelector(`[data-tab="main-attributes"] > ${ERROR_POPUP_SELECTOR}`);
  const mainAttributesTab = form.querySelector(`${TAB_SELECTOR}.main-attributes`);
  const checkListTab = form.querySelector(`${TAB_SELECTOR}.required-check-list`);
  const mainTabActive = mainAttributesTab.classList.contains('active');
  const checkListTabActive = checkListTab && checkListTab.classList.contains('active');

  if (mainTabActive && !checkListValid) {
    checkListInvalidPopup.classList.add('visible');
  } else if (checkListTabActive && !mainAttributesValid && !checkListValid) {
    mainAttributesInvalidPopup.classList.add('visible');
  } else if (checkListTabActive && !mainAttributesValid && checkListValid) {
    toggleAttributesTab(form.closest('.modal-dialog'), 'main-attributes');
  }

  document.addEventListener('click', function hideErrorListener() {
    if (checkListInvalidPopup && mainAttributesInvalidPopup) {
      checkListInvalidPopup.classList.remove('visible');
      mainAttributesInvalidPopup.classList.remove('visible');
    }
    document.removeEventListener('click', hideErrorListener);
  });
}

export { validateCheckList, showTabValidationPopup, toggleAttributesTab };
