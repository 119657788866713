import i18n from '../../i18n';

export default class ValidationService {
  constructor(validationOptions) {
    if (validationOptions) {
      this.passwordRequirements = validationOptions.passwordRequirements;
      this.formatRegExpAttributes = validationOptions.formatRegExpAttributes;
    }
    this.errors = {};
  }

  validate(...parameters) {
    this.errors = {};

    parameters.forEach((parameter) => {
      const [paramName, paramValue, validations] = parameter;

      if (!validations || validations.length === 0) { return; }
      validations.forEach((validation) => {
        if (Object.prototype.hasOwnProperty.call(this.errors, paramName)) { return; }
        this.callValidation(paramValue, paramName, validation);
      });
    });
  }

  isValid() {
    return Object.keys(this.errors).length === 0;
  }

  validationErrors() {
    return this.errors;
  }

  callValidation(value, attributeName, validationName) {
    switch (validationName) {
      case 'presence':
        this.validatePresence(value, attributeName);
        break;
      case 'password':
        this.validatePassword(value, attributeName);
        break;
      case 'confirmation':
        this.validatePasswordConfirmation(value, attributeName);
        break;
      case 'format':
        this.validateFormat(value, attributeName);
        break;
      default:
        break;
    }
  }

  validatePresence(value, attributeName) {
    if (!this.constructor.isPresent(value)) {
      this.errors[attributeName] = i18n.t('errors.blank');
    }
  }

  validatePassword(passwordFields, attributeName) {
    const [password, passwordConfirmation] = passwordFields;

    if (!this.constructor.isPresent(password)) {
      this.errors[attributeName] = i18n.t('errors.blank');
    } else if (!this.isMatchedToComplexity(password)) {
      this.errors[attributeName] = this.passwordRequirements.error_message;
    } else if (this.constructor.isPresent(passwordConfirmation)
               && (password !== passwordConfirmation)) {
      this.errors[attributeName] = i18n.t('errors.unconfirmed');
    }
  }

  validatePasswordConfirmation(passwordFields, attributeName) {
    const passwordConfirmation = passwordFields[1];
    const mainField = attributeName.replace('_confirmation', '');

    if (!this.constructor.isPresent(passwordConfirmation)) {
      this.errors[attributeName] = i18n.t('errors.blank');
    } else if (this.errors[mainField] === i18n.t('errors.unconfirmed')) {
      this.errors[attributeName] = i18n.t('errors.unconfirmed');
    }
  }

  validateFormat(value, attributeName) {
    if (!this.formatRegExpAttributes[attributeName]) { return; }
    const { regExp, errorText } = this.formatRegExpAttributes[attributeName];
    const isValid = regExp.test(value);
    if (!isValid) {
      this.errors[attributeName] = errorText;
    }
  }

  isMatchedToComplexity(password) {
    const fieldLength = password.length;
    const passRegexp = this.passwordRequirements.password_regexp;

    if (fieldLength < this.passwordRequirements.minimum_password_length
        || fieldLength > this.passwordRequirements.maximum_password_length) {
      return false;
    }

    if (passRegexp != null && !RegExp(passRegexp).test(password)) {
      return false;
    }

    return true;
  }

  static isPresent(parameter) {
    const isString = typeof parameter === 'string';
    const isNumber = typeof parameter === 'number';
    const isArray = Array.isArray(parameter);

    if (isString) { return parameter.replace(/\s/g, '') !== ''; }
    if (isNumber) { return parameter >= 0; }
    if (isArray) { return parameter.length !== 0; }

    return false;
  }
}
