`import { remove_error_callback, custom_fail_callback } from 'javascripts/components/shared/rails_validation_helpers'`
`import CustomParametersValidator from 'javascripts/components/custom_parameters/validator'`
`import { showFieldsError, validateFields } from 'javascripts/components/users/password_validation.js'`
`import { validateDatetimeFields } from 'javascripts/components/custom_parameters/datetime_validators'`
`import { validateMultihiddenFields } from 'javascripts/components/issues/form/helpers'`
`import { validateReactFields } from 'javascripts/react/services/ClientSideValidationService'`
`import validateStatusForm from 'javascripts/components/issues/status_form/validations'`
`import i18n from 'javascripts/i18n'`
`import { normalizedDateForSave } from 'javascripts/react/services/date_time_formatter_service'`
`import WorkTypeFieldInterface from 'javascripts/components/issues/form/WorkTypeFieldInterface'`

$(document).on 'ajax:before', '@new-issue-form, @new-issue-cloning-form', (e) ->
  return true unless $(e.target).is('@new-issue-form, @new-issue-cloning-form')

  form              = $(@)
  customParamsBlock = form.find('@form-issue-custom-parameters-list-container')
  customDeadlineAt = form.find('@planned-solution-time')
  repeatAt = form.find('@repetition-at')
  repetitionParamsContainer = form.find('@repetition-params-fields:visible')
  repeatPeriod = form.find('@repeat-period')
  customSelect = form.find('.custom-select [data-validate-field="true"]:enabled')
  multihiddenFields = form.find('[data-field-type="multihidden"]:visible')
  issueTypeIsValid = validateIssueType()

  return false if customSelect.length > 0 && !validateCustomSelect(customSelect)
  return false if customParamsBlock.length > 0 && !validate_issue_custom_parameters(customParamsBlock)
  return false if customDeadlineAt.length > 0 && !validate_deadline_at_field(customDeadlineAt)
  return false if multihiddenFields.length > 0 && !validateMultihiddenFields(multihiddenFields)
  return false if !issueTypeIsValid

  if repetitionParamsContainer.length > 0 && repeatAt.length > 0
    isValidRepeatAt = validateRepeatAt(repeatAt)
    isValidRepeatPeriod = validateRepeatPeriod(repeatPeriod)
    return false if !isValidRepeatAt || !isValidRepeatPeriod

  if form.data('disabled')
    return false
    form.data('disabled', true)

$(document).on 'ajax:before', '@equipment-form', (e) ->
  return true unless $(e.target).is('@equipment-form')

  form              = $(@)
  customParamsBlock = form.find('@new-equipment-custom-parameters-list-container')
  return false if customParamsBlock.length > 0 && !validate_issue_custom_parameters(customParamsBlock)
  return false if !validateReactFields()

  if form.data('disabled')
    return false
    form.data('disabled', true)

$(document).on 'ajax:before', '@new-employee-form', (e) ->
  return true unless $(e.target).is('@new-employee-form')

  form = $(@)
  customSelect = form.find('.custom-select [data-validate-field="true"]:enabled')
  return false if customSelect.length > 0 && !validateCustomSelect(customSelect)

  if form.data('disabled')
    return false
    form.data('disabled', true)

window.ClientSideValidations.callbacks.form.fail = (form, eventData) ->
  multilevelSelect = form.find('.multilevel-select')
  multihiddenFields = form.find('[data-field-type="multihidden"]:visible')
  validate_multilevel_select(multilevelSelect) if multilevelSelect.length > 0

  customSelect = form.find('.custom-select [data-validate-field="true"]:enabled')
  validateCustomSelect(customSelect) if customSelect.length > 0

  newIssueCustomParamsBlock = form.find('@form-issue-custom-parameters-list-container')
  validate_issue_custom_parameters(newIssueCustomParamsBlock) if newIssueCustomParamsBlock.length > 0

  updateIssueStatusCustomParamsBlock = form.find('@update-issue-status-custom-parameters-list-container')
  if updateIssueStatusCustomParamsBlock.length > 0
    validate_issue_custom_parameters(updateIssueStatusCustomParamsBlock)

  companyCustomParamsBlock = form.find('@new-company-parameters-list-container')
  validate_issue_custom_parameters(companyCustomParamsBlock) if companyCustomParamsBlock.length > 0

  contactCustomParamsBlock = form.find('@new-contact-parameters-list-container')
  validate_issue_custom_parameters(contactCustomParamsBlock) if contactCustomParamsBlock.length > 0

  employeeCustomParamsBlock = form.find('@new-employee-parameters-list-container')
  validate_issue_custom_parameters(employeeCustomParamsBlock) if employeeCustomParamsBlock.length > 0

  agreementCustomParamsBlock = form.find('@new-agreement-parameters-list-container')
  validate_issue_custom_parameters(agreementCustomParamsBlock) if agreementCustomParamsBlock.length > 0

  maintenanceEntityParamsBlock = form.find('@new-company-maintenance-entity-parameters-list-container')
  validate_issue_custom_parameters(maintenanceEntityParamsBlock) if maintenanceEntityParamsBlock.length > 0

  validateMultihiddenFields(multihiddenFields) if multihiddenFields.length > 0

  customDeadlineAt = form.find('@planned-solution-time')
  validate_deadline_at_field(customDeadlineAt) if customDeadlineAt.length > 0

  validateStatusForm(form) if form.find('.main-attributes@form-tab')

  validateReactFields()
  validateIssueType()

  repeatAt = form.find('@repetition-at')
  repeatPeriod = form.find('@repeat-period')
  repetitionParamsContainer = form.find('@repetition-params-fields:visible')

  validateRepeatAt(repeatAt) if repetitionParamsContainer.length > 0 && repeatAt.length > 0
  validateRepeatPeriod(repeatPeriod) if repetitionParamsContainer.length > 0 && repeatPeriod.length > 0

  userFormWithPassword = $('@user-form-with-pass')
  if (userFormWithPassword.length > 0)
    userType = form.attr('data-user-type')
    password = form.find("##{userType}_password")
    confirmation = form.find("##{userType}_password_confirmation")
    currentPassword = form.find("##{userType}_current_password")
    validationErrors = validateFields(password, confirmation, currentPassword, userType)
    showFieldsError([password, confirmation], validationErrors)

window.ClientSideValidations.callbacks.element.fail = (element, message, callback) ->
  remove_error_callback(element) if element.closest('.form-group').find('.custom-error').length > 0
  custom_select = element.closest('.custom-select')
  if custom_select.length > 0
    custom_fail_callback(custom_select, message)
  else
    callback()

  # confirmation validation
  confirmation_element = $("##{element.attr('id')}_confirmation")
  if confirmation_element.length > 0
    custom_fail_callback(confirmation_element, message)

validateCustomSelect = (elements) ->
  result = true
  elements.each (_index, element) ->
    input = $(element)
    holder = input.closest('.form-group')
    emptyValue =
      if input.attr('multiple')
        if input.attr('remote') then [''] else null
      else
        ''

    remove_error_callback(input) if holder.find('.custom-error').length > 0
    if _.isEqual(input.val(), emptyValue)
      custom_fail_callback(input, input.data('error'))
      result = false
      return

  result

validate_multilevel_select = (container) ->
  validate_fields = container.attr('data-validate-fields')
  return unless validate_fields

  $.each validate_fields.split(' '), (index, field_id) ->
    field = container.find("##{field_id}")
    if field.val() == ''
      custom_fail_callback(field, container.attr('data-error'))
    else
      remove_error_callback(field)

validate_issue_custom_parameters = (container) ->
  custom_parameters_validator = new CustomParametersValidator(container);
  return custom_parameters_validator.validate();

validate_deadline_at_field = (element) ->
  result = true
  date_input = element.find('input@issue-datetime-picker')
  current_date = new Date()

  if date_input.val().length > 0
    time_inputs = element.find('input@time-picker')
    time = time_inputs[0].value + ':' + time_inputs[1].value
    normalizedDate = normalizedDateForSave(date_input.val())
    selected_date = new Date("#{normalizedDate} #{time} #{element.attr('data-timezone-offset')}")
  else if !date_input.prop('disabled')
    custom_fail_callback(date_input, i18n.t('errors.blank'))
    return false

  if selected_date && current_date > selected_date
    custom_fail_callback(date_input, element.data('error-message'))
    result = false

  return result

validateIssueType = ->
  workTypeField = new WorkTypeFieldInterface()
  if $(workTypeField.workTypeBlock).is(':visible') && !workTypeField.selectedTypeId()
    workTypeField.update({ error: i18n.t('errors.blank') })
    return false

  return true

validateRepeatAt = (element) ->
  remove_error_callback(element) if element.find('.custom-error').length > 0

  date_input = element.find('input@issue-datetime-picker')
  if date_input.val() == ''
    custom_fail_callback(date_input, element.data('empty-date-message'))
    return false
  time_inputs = element.find('input@time-picker')
  time = time_inputs[0].value + ':' + time_inputs[1].value
  normalizedDate = normalizedDateForSave(date_input.val())
  selected_date = new Date("#{normalizedDate} #{time}")
  current_date = new Date()
  selected_date_input_value = new Date(normalizedDate)
  selected_date_without_time = selected_date_input_value.setHours(0, 0, 0, 0)
  current_date_without_time = current_date.setHours(0, 0, 0, 0)
  if selected_date_without_time == current_date_without_time
    custom_fail_callback(date_input, element.data('current-date-error-message'))
    return false

  if current_date > selected_date
    custom_fail_callback(date_input, element.data('error-message'))
    return false

  return true

validateRepeatPeriod = (element) ->
  remove_error_callback(element) if element.find('.custom-error').length > 0

  input = element.find('input[type="text"]')
  if input.val() == ''
    custom_fail_callback(input, element.data('empty-date-message'))
    return false
  true

window.ClientSideValidations.callbacks.element.pass = (element, removeError, eventData) ->
  custom_select = element.closest('.custom-select')
  if custom_select.length > 0
    remove_error_callback(custom_select)

  datetimeWrapper = element.closest('@custom-parameters-datetime-inputs-wrapper')
  if datetimeWrapper.length > 0
    validateDatetimeFields(datetimeWrapper) && remove_error_callback(datetimeWrapper)
  else
    removeError()

  if element.length > 0 && (element[0].id == 'issue_repeat_at_date' || element[0].id == 'issue_repeat_period' )
    remove_error_callback(element)

  confirmation_element = $("##{element.attr('id')}_confirmation")
  if confirmation_element.length > 0
    remove_error_callback(confirmation_element)

window.ClientSideValidations.validators.remote['email_uniqueness'] = (element, options) ->
  response = {}
  request = $.ajax({
    url: element.data('validate-url'),
    data: { email: element.val() },
    async: false,
    success: (data) -> response = data
  })
  response.error

window.ClientSideValidations.validators.remote['login_uniqueness'] = (element, options) ->
  response = {}
  request = $.ajax({
    url: element.data('validate-url'),
    data: { login: element.val() },
    async: false,
    success: (data) -> response = data
  })
  response.error

window.ClientSideValidations.validators.local.numericality = (element, options) ->
  val = jQuery.trim(element.val())

  if !options['only_integer']
    $form = $(element[0].form);
    number_format = $form[0].ClientSideValidations.settings.number_format;
    val = val.replace(new RegExp("\\" + number_format.separator, 'g'), '.').replace(new RegExp("\\,",'g'),".");

  unless ClientSideValidations.patterns.numericality["default"].test(val)
    return if options.allow_blank == true and @presence(element, {message: options.messages.numericality})
    return options.messages.numericality

  if options.only_integer and !/^[+-]?\d+$/.test(val)
    return options.messages.only_integer

  CHECKS =
    greater_than: '>'
    greater_than_or_equal_to: '>='
    equal_to: '=='
    less_than: '<'
    less_than_or_equal_to: '<='

  form = $(element[0].form)
  # options[check] may be 0 so we must check for undefined
  for check, operator of CHECKS when options[check]?
    if !isNaN(parseFloat(options[check])) && isFinite(options[check])
      check_value = options[check]
    else if form.find("[name*=#{options[check]}]").size() == 1
      check_value = form.find("[name*=#{options[check]}]").val()
    else
      return

    fn = new Function("return #{val} #{operator} #{check_value}")
    return options.messages[check] unless fn()

  if options.odd and !(parseInt(val, 10) % 2)
    return options.messages.odd

  if options.even and (parseInt(val, 10) % 2)
    return options.messages.even
window.ClientSideValidations.selectors.inputs += ', select, input[style="display: none"][data-validate="true"]';
window.ClientSideValidations.selectors.validate_inputs += ', select, input[style="display: none"][data-validate="true"]';

`export default validate_issue_custom_parameters`
