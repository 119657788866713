reinitialize_ckeditor = ->
  if typeof(CKEDITOR) != "undefined"
    for name of CKEDITOR.instances
      if name?
        instance = CKEDITOR.instances[name]
        reinitialize_ckeditor_instance(instance, name)

reinitialize_ckeditor_instance = (instance, name) ->
  if instance?
    $ck_element = $('#' + name)
    $ck_element.addClass('ckeditor-reinit')
    $ck_element.data('ck-toolbar', instance.config.toolbar)
    $ck_element.data('ck-height', instance.config.height)
    $ck_element.data('ck-language', instance.config.language)
    instance.destroy()

`export { reinitialize_ckeditor, reinitialize_ckeditor_instance }`
